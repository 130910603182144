/* eslint-disable */
import React, { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Navbar from "./Navbar";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  welcomeBlock: {
    width: 500,
    height: 100,
    padding: 50,
  },
  root: {
    display: "flex",
    height: "100%",
    background: "#f5f7fa",
  },
  footerGrid: {
    position: "relative",
  },
}));

export const Pages = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Navbar />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {props.children}
        </Grid>
        <Grid item xs={12} className={classes.footerGrid}></Grid>
      </Grid>
    </div>
  );
};

export default Pages;
