import React from "react";
import Highcharts from "highcharts";
import HighchartsMap from "highcharts/modules/map";
import HighchartsReact from "highcharts-react-official";
import tract from "../../public/tract.json";
import { useState } from "react";
import exporting from "highcharts/modules/exporting";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Skeleton } from "@mui/material";


exporting(Highcharts);
HighchartsMap(Highcharts);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MichTractComponent({tractDataTop, tractData}) {
  const [mapLayer, setMapLayer] = useState("asthma");
  const [loading, setLoading] = useState(false);
  const [tractB, setTractB] = useState(tract);
  const [tractName, setTractName] = useState([]);
  const [hieAsthmaRatesTotal, setHieAsthmaRatesTotal] = useState("");
  const [hieBhRatesTotal, setHieBhRatesTotal] = useState("");
  const [hieDiabetesRatesTotal, setHieDiabetesRatesTotal] = useState("");
  const [pdmpAsthmaRatesTotal, setPdmpAsthmaRatesTotal] = useState("");
  const [pdmpBhRatesTotal, setPdmpBhRatesTotal] = useState("");
  const [pdmpDiabetesRatesTotal, setPdmpDiabetesRatesTotal] = useState("");

  const handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setTractName(value);
    const data = tract.features;
    const filterData = search(data, value);
    if (!value) {
      setTractB(tract);
    } else {
      setTractB(filterData);
    }
    
    const res = getCalculatedAverage(value, tractData);
    res.finalHieAsthmaRates
      ? setHieAsthmaRatesTotal(parseFloat(res.finalHieAsthmaRates).toFixed(2))
      : setHieAsthmaRatesTotal(null);
    res.finalHieBhRates
      ? setHieBhRatesTotal(parseFloat(res.finalHieBhRates).toFixed(2))
      : setHieBhRatesTotal(null);
    res.finalHieDiabetesaRates
      ? setHieDiabetesRatesTotal(
          parseFloat(res.finalHieDiabetesaRates).toFixed(2)
        )
      : setHieDiabetesRatesTotal(null);
    res.finalPdmpAsthmaRates
      ? setPdmpAsthmaRatesTotal(parseFloat(res.finalPdmpAsthmaRates).toFixed(2))
      : setPdmpAsthmaRatesTotal(null);
    res.finalPdmpBhRates
      ? setPdmpBhRatesTotal(parseFloat(res.finalPdmpBhRates).toFixed(2))
      : setPdmpBhRatesTotal(null);
    res.finalPdmpDiabetesaRates
      ? setPdmpDiabetesRatesTotal(
          parseFloat(res.finalPdmpDiabetesaRates).toFixed(2)
        )
      : setPdmpDiabetesRatesTotal(null);
  };

  const getCalculatedAverage = (arr, values) => {
    const res = values.filter((e) => {
      return arr.some((k) => e.name === k);
    });
    const hieBaseLineTract = res.reduce((acc, obj) => {
      return acc + obj.HIE_Baseline_Tract;
    }, 0);
    const asthmaTractSumHie = res.reduce((acc, obj) => {
      return acc + obj.HIE_Asthma_Tract;
    }, 0);
    const bhTractSumHie = res.reduce((acc, obj) => {
      return acc + obj.HIE_BH_Tract;
    }, 0);
    const diabetesTractSumHie = res.reduce((acc, obj) => {
      return acc + obj.HIE_Diabetes_Tract;
    }, 0);

    const pdmpBaseLineTract = res.reduce((acc, obj) => {
      return acc + obj.PDMP_Baseline_Tract;
    }, 0);
    const asthmaTractSumPdmp = res.reduce((acc, obj) => {
      return acc + obj.PDMP_Asthma_Tract;
    }, 0);
    const bhTractSumPdmp = res.reduce((acc, obj) => {
      return acc + obj.PDMP_BH_Tract;
    }, 0);
    const diabetesTractSumPdmp = res.reduce((acc, obj) => {
      return acc + obj.PDMP_Diabetes_Tract;
    }, 0);

    const finalHieAsthmaRates = (asthmaTractSumHie / hieBaseLineTract) * 100;
    const finalHieBhRates = (bhTractSumHie / hieBaseLineTract) * 100;
    const finalHieDiabetesaRates =
      (diabetesTractSumHie / hieBaseLineTract) * 100;

    const finalPdmpAsthmaRates = (asthmaTractSumPdmp / pdmpBaseLineTract) * 100;
    const finalPdmpBhRates = (bhTractSumPdmp / pdmpBaseLineTract) * 100;
    const finalPdmpDiabetesaRates =
      (diabetesTractSumPdmp / pdmpBaseLineTract) * 100;

    return {
      finalHieAsthmaRates,
      finalHieBhRates,
      finalHieDiabetesaRates,
      finalPdmpAsthmaRates,
      finalPdmpBhRates,
      finalPdmpDiabetesaRates,
    };
  };

  const names = tractData.map((e) => {
    return e.name;
  });
  function search(input, keys) {
    const res = Object.fromEntries(
      Object.entries(input).filter(([, { properties }]) =>
        keys.some((key) => properties.name.includes(key))
      )
    );
    return { type: "FeatureCollection", features: Object.values(res) };
  }
  const addHyphen = (str) => {
    if (str === "025") {
      let str1 = str.slice(0, 1);
      let str2 = str.slice(1, 3);
      return str1 + "-" + str2;
    } else {
      let str1 = str.slice(0, 2);
      let str2 = str.slice(2);
      return str1 + "-" + str2;
    }
  };

  const generateColor = (str) => {
    if (str >= "2.5%") {
      return `<span style="color: red" >${str}%</span>`;
    } else {
      return `<span style="color: green" >${str}% </span>`;
    }
  };

  const generateColorLabel = (str) => {
    if (str === "Red") {
      return `<span style="color: red" >${str}</span>`;
    } else if (str === "Green") {
      return `<span style="color: green" >${str}</span>`;
    }
  };

  const tractDataMapHieAsthma = tractData.map(
    ({ name: name, HIE_Asthma_to_State_Rate_percentile: value, ...rest }) => ({
      name,
      value,
      ...rest,
    })
  );

  const tractDataMapPdmpAsthma = tractData.map(
    ({
      name: name,
      PDMP_Asthma__to_State_Rate_percentile: value,
      ...rest
    }) => ({
      name,
      value,
      ...rest,
    })
  );

  const tractDataMapHieBh = tractData.map(
    ({ name: name, HIE_BH_to_State_Rate_percentile: value, ...rest }) => ({
      name,
      value,
      ...rest,
    })
  );

  const tractDataMapPdmpBh = tractData.map(
    ({ name: name, PDMP_BH_to_State_Rate_percentile: value, ...rest }) => ({
      name,
      value,
      ...rest,
    })
  );

  const tractDataMapHieDiabetes = tractData.map(
    ({
      name: name,
      HIE_Diabetes_to_State_Rate_percentile: value,
      ...rest
    }) => ({
      name,
      value,
      ...rest,
    })
  );

  const tractDataMapPdmpDiabetes = tractData.map(
    ({
      name: name,
      PDMP_Diabetes_to_State_Rate_percentile: value,
      ...rest
    }) => ({
      name,
      value,
      ...rest,
    })
  );

  const options = {
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen"],
        },
      },
    },
    chart: {
      map: tractB,
      width: 650,
    },

    title: {
      text: "Nebraska (HIE) - Tract",
    },

    credits: {
      enabled: false,
    },

    subtitle: {
      text: "",
      floating: true,
      align: "right",
      y: 50,
      style: {
        fontSize: "16px",
      },
    },
    legend: {
      title: {
        text: "",
        style: {
          color:
            // theme
            (Highcharts.defaultOptions &&
              Highcharts.defaultOptions.legend &&
              Highcharts.defaultOptions.legend.title &&
              Highcharts.defaultOptions.legend.title.style &&
              Highcharts.defaultOptions.legend.title.style.color) ||
            "black",
        },
      },
      itemStyle: {
        fontSize: ".75rem",
      },
      labelFormatter: function () {
        return '<div style="font-weight: 300">' + this.name + "</div>";
      },
      align: "center",
      verticalAlign: "bottom",
      squareSymbol: false,
      valueDecimals: 2,
      symbolRadius: 2,
      symbolHeight: 8,
      symbolWidth: 64,
    },
    colorAxis: {
      min: 0,
      dataClasses: [
        {
          from: "025",
          to: "025",
          color: "#59A14F",
          name: "0-25%",
        },
        {
          from: "2550",
          to: "2550",
          color: "#76B7B2",
          name: "25-50%",
        },
        {
          from: "5075",
          to: "5075",
          color: "#EDC948",
          name: "50-75%",
        },
        {
          from: "75100",
          to: "75100",
          color: "#A90C38",
          name: "75-100%",
        },
      ],
    },
    plotOptions: {
      series: {
        point: {
          events: {
            select: function () {
              document.getElementById("hieAsthmaRates").innerHTML =
                this.HIE_TR_Asthma_Rates + "%";
              document.getElementById("hieBehaviourRates").innerHTML =
                this.HIE_TR_Behavior_Health_Rates + "%";
              document.getElementById("hieDiabetesRates").innerHTML =
                this.HIE_Diabetes_Health_Rates + "%";
              document.getElementById("pdmpAsthmaRates").innerHTML =
                this.PDMP_TR_Asthma_Rates + "%";
              document.getElementById("pdmpBehaviourRates").innerHTML =
                this.PDMP_TR_Behavior_Health_Rates + "%";
              document.getElementById("pdmpDiabetesRates").innerHTML =
                this.PDMP_TR_Diabetes_Rates + "%";
            },
            unselect: function () {
              document.getElementById("hieAsthmaRates").innerHTML =
                tractDataTop.HIE_TR_Asthma_Rates_BG + "%";
              document.getElementById("hieBehaviourRates").innerHTML =
                tractDataTop.HIE_TR_Behavior_Health_Rates_BG + "%";
              document.getElementById("hieDiabetesRates").innerHTML =
                tractDataTop.HIE_TR_Diabetes_Rates_BG + "%";
              document.getElementById("pdmpAsthmaRates").innerHTML =
                tractDataTop.PDMP_TR_Asthma_Rates_BG + "%";
              document.getElementById("pdmpBehaviourRates").innerHTML =
                tractDataTop.PDMP_TR_Behavior_Health_Rates_BG + "%";
              document.getElementById("pdmpDiabetesRates").innerHTML =
                tractDataTop.HIE_TR_Diabetes_Rates_BG + "%";
            },
          },
        },
      },
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },
    tooltip: {
      formatter: function () {
        return (
          '<div style="background:white; font-size: 12px"><b>Tract: ' +
          this.point.name +
          "</b><br>" +
          (mapLayer === "asthma"
            ? "Asthma"
            : mapLayer === "behaviour"
            ? "Behaviour"
            : mapLayer === "diabetes"
            ? "Diabetes"
            : "Asthma") +
          " to HIE State Rate:" +
          addHyphen(this.point.value) +
          "%" +
          "<br>" +
          (mapLayer === "asthma"
            ? "Asthma"
            : mapLayer === "behaviour"
            ? "Behaviour"
            : mapLayer === "diabetes"
            ? "Diabetes"
            : "Asthma") +
          " Tract Per 1000:" +
          (mapLayer === "asthma"
            ? this.point.HIE_Asthma_by_Tract
            : mapLayer === "behaviour"
            ? this.point.HIE_BH_by_Tract
            : mapLayer === "diabetes"
            ? this.point.HIE_Diabetes_by_Tract
            : this.point.HIE_Asthma_by_Tract) +
          "<br>" +
          "Asthma Rates:" +
          "<b>" +
          generateColor(this.point.HIE_TR_Asthma_Rates) +
          "</b>" +
          "<br>" +
          "Diabetes Rates:" +
          "<b>" +
          generateColor(this.point.HIE_Diabetes_Health_Rates) +
          "</b>" +
          "<br>" +
          "Behaviour Health Rates:" +
          "<b>" +
          generateColor(this.point.HIE_TR_Behavior_Health_Rates) +
          "</b>" +
          "<br>" +
          "<br>" +
          "<span>" +
          "<b>" +
          generateColorLabel("Green") +
          "</b>" +
          " represents- Below State Average</span>" +
          "<br>" +
          "<span>" +
          "<b>" +
          generateColorLabel("Red") +
          "</b>" +
          " represents- Above State Average</span>" +
          "<br>" +
          "</div>"
        );
      },
    },
    series: [
      {
        data:
          mapLayer === "asthma"
            ? tractDataMapHieAsthma
            : mapLayer === "behaviour"
            ? tractDataMapHieBh
            : mapLayer === "diabetes"
            ? tractDataMapHieDiabetes
            : tractDataMapHieAsthma,
        keys: ["value"],
        joinBy: "name",
        name: "Tract",
        borderColor: "#202020",
        allowPointSelect: true,
        cursor: "pointer",
        borderWidth: 1,
        // dataLabels: {
        //   enabled: false,
        //   format: "{point.properties.name}",
        // },
        states: {
          hover: {
            enabled: true,
            borderWidth: 3,
            color: "#a4edba",
            borderColor: "black",
          },
          select: {
            color: "#EFFFEF",
            borderColor: "black",
            borderWidth: 3,
          },
        },
      },
    ],
  };
  const options1 = {
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen"],
        },
      },
    },
    chart: {
      map: tractB,
      width: 650,
    },

    title: {
      text: "Nebraska (PDMP)- Tract",
    },

    credits: {
      enabled: false,
    },

    subtitle: {
      text: "",
      floating: true,
      align: "right",
      y: 50,
      style: {
        fontSize: "16px",
      },
    },
    legend: {
      title: {
        text: "",
        style: {
          color:
            // theme
            (Highcharts.defaultOptions &&
              Highcharts.defaultOptions.legend &&
              Highcharts.defaultOptions.legend.title &&
              Highcharts.defaultOptions.legend.title.style &&
              Highcharts.defaultOptions.legend.title.style.color) ||
            "black",
        },
      },
      itemStyle: {
        fontSize: ".75rem",
      },
      labelFormatter: function () {
        return '<div style="font-weight: 300">' + this.name + "</div>";
      },
      align: "center",
      verticalAlign: "bottom",
      squareSymbol: false,
      valueDecimals: 2,
      symbolRadius: 2,
      symbolHeight: 8,
      symbolWidth: 64,
    },
    colorAxis: {
      min: 0,
      dataClasses: [
        {
          from: "025",
          to: "025",
          color: "#59A14F",
          name: "0-25%",
        },
        {
          from: "2550",
          to: "2550",
          color: "#76B7B2",
          name: "25-50%",
        },
        {
          from: "5075",
          to: "5075",
          color: "#EDC948",
          name: "50-75%",
        },
        {
          from: "75100",
          to: "75100",
          color: "#A90C38",
          name: "75-100%",
        },
      ],
    },
    plotOptions: {
      series: {
        point: {
          events: {
            select: function () {
              document.getElementById("hieAsthmaRates").innerHTML =
                this.HIE_TR_Asthma_Rates + "%";
              document.getElementById("hieBehaviourRates").innerHTML =
                this.HIE_TR_Behavior_Health_Rates + "%";
              document.getElementById("hieDiabetesRates").innerHTML =
                this.HIE_Diabetes_Health_Rates + "%";
              document.getElementById("pdmpAsthmaRates").innerHTML =
                this.PDMP_TR_Asthma_Rates + "%";
              document.getElementById("pdmpBehaviourRates").innerHTML =
                this.PDMP_TR_Behavior_Health_Rates + "%";
              document.getElementById("pdmpDiabetesRates").innerHTML =
                this.PDMP_TR_Diabetes_Rates + "%";
            },
            unselect: function () {
              document.getElementById("hieAsthmaRates").innerHTML =
                tractDataTop.HIE_TR_Asthma_Rates_BG + "%";
              document.getElementById("hieBehaviourRates").innerHTML =
                tractDataTop.HIE_TR_Behavior_Health_Rates_BG + "%";
              document.getElementById("hieDiabetesRates").innerHTML =
                tractDataTop.HIE_TR_Diabetes_Rates_BG + "%";
              document.getElementById("pdmpAsthmaRates").innerHTML =
                tractDataTop.PDMP_TR_Asthma_Rates_BG + "%";
              document.getElementById("pdmpBehaviourRates").innerHTML =
                tractDataTop.PDMP_TR_Behavior_Health_Rates_BG + "%";
              document.getElementById("pdmpDiabetesRates").innerHTML =
                tractDataTop.HIE_TR_Diabetes_Rates_BG + "%";
            },
          },
        },
      },
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },
    tooltip: {
      formatter: function () {
        return (
          "<div><b>Tract: " +
          this.point.name +
          "</b><br>" +
          (mapLayer === "asthma"
            ? "Asthma"
            : mapLayer === "behaviour"
            ? "Behaviour"
            : mapLayer === "diabetes"
            ? "Diabetes"
            : "Asthma") +
          " to HIE State Rate:" +
          addHyphen(this.point.value) +
          "%" +
          "<br>" +
          (mapLayer === "asthma"
            ? "Asthma"
            : mapLayer === "behaviour"
            ? "Behaviour"
            : mapLayer === "diabetes"
            ? "Diabetes"
            : "Asthma") +
          " Tract Per 1000:" +
          (mapLayer === "asthma"
            ? this.point.PDMP_Asthma_by_Tract
            : mapLayer === "behaviour"
            ? this.point.PDMP_BH_by_Tract
            : mapLayer === "diabetes"
            ? this.point.PDMP_Diabetes_by_Tract
            : this.point.PDMP_Asthma_by_Tract) +
          "<br>" +
          "Asthma Rates:" +
          "<b>" +
          generateColor(this.point.PDMP_TR_Asthma_Rates) +
          "</b>" +
          "<br>" +
          "Diabetes Rates:" +
          "<b>" +
          generateColor(this.point.PDMP_TR_Diabetes_Rates) +
          "</b>" +
          "<br>" +
          "Behaviour Health Rates:" +
          "<b>" +
          generateColor(this.point.PDMP_TR_Behavior_Health_Rates) +
          "</b>" +
          "<br>" +
          "<br>" +
          "<span>" +
          "<b>" +
          generateColorLabel("Green") +
          "</b>" +
          " represents- Below State Average</span>" +
          "<br>" +
          "<span>" +
          "<b>" +
          generateColorLabel("Red") +
          "</b>" +
          " represents- Above State Average</span>" +
          "<br>" +
          "</div>"
        );
      },
    },

    series: [
      {
        data:
          mapLayer === "asthma"
            ? tractDataMapPdmpAsthma
            : mapLayer === "behaviour"
            ? tractDataMapPdmpBh
            : mapLayer === "diabetes"
            ? tractDataMapPdmpDiabetes
            : tractDataMapPdmpAsthma,
        keys: ["value"],

        joinBy: "name",
        name: "Tract",
        borderColor: "#202020",
        allowPointSelect: true,
        cursor: "pointer",

        states: {
          hover: {
            enabled: true,
            borderWidth: 3,
            color: "#a4edba",
            borderColor: "black",
          },
          select: {
            color: "#EFFFEF",
            borderColor: "black",
            borderWidth: 3,
          },
        },

        // dataLabels: {
        //   enabled: false,
        //   format: "{point.properties.name}",
        // },
      },
    ],
  };

  // const handleClick = (a, b, c, d, e, f) => {
  //   setHieAsthmaByTract(a);
  //   setHieBehaviourByTract(b);
  //   setHieDiabetesByTract(c);

  //   setPdmpAsthmaByTract(d);
  //   setPdmpBehaviourByTract(e);
  //   setPdmpDiabetesByTract(f);
  // };

  // const handleClick1= (arr) => {
  //   setTotalData(arr)
  //   console.log(tractDataTop)
  // }

  return (
    <div className="pt-2 mt-1">
      <div className="row pt-4 pb-4">
        <div className="col-sm-2">
          {/* <Example/> */}
          <div className="card">
            <div className="card-body">
              <div className="h2 font-weight-bold">
                {hieAsthmaRatesTotal ? (
                  hieAsthmaRatesTotal + "%"
                ) : (
                  <span
                    id="hieAsthmaRates"
                    className="h2 font-weight-bold"
                  >
                    {tractDataTop.HIE_TR_Asthma_Rates_BG}%
                  </span>
                )}
              </div>

              <div className="card-text text-muted">HIE Asthma Rates</div>
            </div>
          </div>
        </div>

        <div className="col-sm-2">
          <div className="card">
            <div className="card-body">
              <div className="h2 font-weight-bold">
                {hieBhRatesTotal ? (
                  hieBhRatesTotal + "%"
                ) : (
                  <span id="hieBehaviourRates" className="h2 font-weight-bold">
                    {tractDataTop.HIE_TR_Behavior_Health_Rates_BG}%
                  </span>
                )}
              </div>
              <div className="card-text text-muted">HIE Behavior Rates</div>
            </div>
          </div>
        </div>

        <div className="col-sm-2">
          <div className="card">
            <div className="card-body">
              <div className="h2 font-weight-bold">
                {" "}
                {hieDiabetesRatesTotal ? (
                  hieDiabetesRatesTotal + "%"
                ) : (
                  <span
                    id="hieDiabetesRates"
                    className="h2 font-weight-bold"
                  >
                    {tractDataTop.HIE_TR_Diabetes_Rates_BG}%
                  </span>
                )}
              </div>
              <div className="card-text text-muted">HIE Diabetes Rates</div>
            </div>
          </div>
        </div>

        <div className="col-sm-2">
          <div className="card">
            <div className="card-body">
              <div className="h2 font-weight-bold">
                {" "}
                {pdmpAsthmaRatesTotal ? (
                  pdmpAsthmaRatesTotal
                ) : (
                  <span
                    id="pdmpAsthmaRates"
                    className="h2 font-weight-bold"
                  >
                    {tractDataTop.PDMP_TR_Asthma_Rates_BG}%
                  </span>
                )}
              </div>
              <div className="card-text text-muted">PDMP Asthma Rates</div>
            </div>
          </div>
        </div>
        <div className="col-sm-2">
          <div className="card">
            <div className="card-body">
              <div className="h2 font-weight-bold">
                {pdmpBhRatesTotal ? (
                  pdmpBhRatesTotal + "%"
                ) : (
                  <span
                    id="pdmpBehaviourRates"
                    className="h2 font-weight-bold"
                  >
                    {tractDataTop.PDMP_TR_Behavior_Health_Rates_BG}%
                  </span>
                )}
              </div>
              <div className="card-text text-muted">PDMP Behaviour Rates</div>
            </div>
          </div>
        </div>
        <div className="col-sm-2">
          <div className="card">
            <div className="card-body">
              <div className="h2 font-weight-bold">
                {pdmpDiabetesRatesTotal ? (
                  pdmpDiabetesRatesTotal + "%"
                ) : (
                  <span
                    id="pdmpDiabetesRates"
                    className="h2 font-weight-bold"
                  >
                    {tractDataTop.PDMP_TR_Diabetes_Rates_BG}%
                  </span>
                )}
              </div>
              <div className="card-text text-muted">PDMP Diabetes Rates</div>
            </div>
          </div>
        </div>
      </div>
      <label style={{ padding: "10px", paddingBottom: "0" }}>
        <input
          type="radio"
          name="radio"
          value="asthma"
          checked={mapLayer === "asthma" ? true : false}
          onChange={(e) => setMapLayer(e.target.value)}
        />
        Asthma
      </label>

      <label style={{ padding: "10px", paddingBottom: "0" }}>
        <input
          type="radio"
          name="radio"
          value="behaviour"
          onChange={(e) => setMapLayer(e.target.value)}
        />
        Behaviour
      </label>

      <label style={{ padding: "10px", paddingBottom: "0" }}>
        <input
          type="radio"
          name="radio"
          value="diabetes"
          onChange={(e) => setMapLayer(e.target.value)}
        />
        Diabetes
      </label>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Filter Tracts</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={tractName}
          onChange={handleChange}
          input={<OutlinedInput label="Filter Tracts" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          <MenuItem key="" value="">
            <ListItemText primary="All Tracts" />
          </MenuItem>
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={tractName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ margin: "10px", padding: "10px" }}>
          {options ? <HighchartsReact
            highcharts={Highcharts}
            constructorType={"mapChart"}
            options={options}
          /> : ""}
          
        </div>
        <div style={{ margin: "10px", padding: "10px" }}>
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"mapChart"}
            options={options1}
          />
        </div>
      </div>
    </div>
  );
}
