import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import MichTractComponent from "./MichTractComponent";
import MichSchoolComponent from "./MichSchoolComponent";
import { useOktaAuth } from "@okta/okta-react";
import { Divider, LinearProgress } from "@mui/material";
import axios from "axios";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "#2F5E8C",
  },
  text: {
    marginLeft: "-20px",
    fontStyle: "bold",
  },
  icon: {
    color: "#2F5E8C",
    marginLeft: "-10px",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .Mui-selected": {
      backgroundColor: "#2F5E8C",
      color: "white",
      boxShadow: "4px 4px 20px 0px black",
    },
  },
});
function a11yProps(index) {
  return {
    id: `full-width-tabpanel-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
export default function MichMapGroups() {
  const classes = useStyles();
  const [schoolDistrictData, setSchoolDistrictData] = useState([]);
  const { authState, oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState(false);
  const [schoolDataTop, setSchoolDataTop] = useState("");
  const [tractData, setTractData] = useState([]);
  const [tractDataTop, setTractDataTop] = useState("");
  const [value, setValue] = React.useState(
    parseInt(sessionStorage.getItem("tabValueMich")) || 0
  );
  const accessTokenResponse = authState.accessToken;
  const bearerToken = accessTokenResponse?.accessToken;

  const getTractData = async () => {
    setLoading(true);
    try {
      const url = process.env.REACT_APP_BASE_API + "/tract";
      const result = await axios
        .get(url, {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // setData(res.data.per_hospital_summary);
          setTractData(res.data.tractData);
          setTractDataTop(res.data.tractTopMetrics);
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const getSchoolData = async () => {
    setLoading(true);
    try {
      const url = process.env.REACT_APP_BASE_API + "/school";
      const result = await axios
        .get(url, {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // setData(res.data.per_hospital_summary);
          setSchoolDistrictData(res.data.schoolData);
          setSchoolDataTop(res.data.schoolTopMetrics);
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  };
  // useEffect(() => {
  //   setLoading(true);
  //   // GET request using fetch inside useEffect React hook
  //   const url = process.env.REACT_APP_BASE_API + "/tract";
  //   fetch(url, {
  //     headers: {
  //       Authorization: bearerToken,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setTractData(data.tractData);
  //       setTractDataTop(data.tractTopMetrics);
  //       setLoading(false);
  //     });
  // }, []);

  useEffect(() => {
    getSchoolData();
    getTractData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.setItem("tabValueMich", newValue);
  };
  return (
    <div>
      <Box sx={{ marginTop: "65px", border: 1, borderColor: "divider" }}>
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab
            sx={{
              border: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="School District"
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              border: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="Tract"
            {...a11yProps(1)}
          />
        </Tabs>
        <Divider />
        <TabPanel value={value} index={0}>
          {loading ? (
            <LinearProgress />
          ) : (
            <MichSchoolComponent
              schoolDataTop={schoolDataTop}
              schoolData={schoolDistrictData}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {loading ? (
            <LinearProgress />
          ) : (
            <MichTractComponent
              tractDataTop={tractDataTop}
              tractData={tractData}
            />
          )}
        </TabPanel>
      </Box>
    </div>
  );
}
